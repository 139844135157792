<template>
  <div>
    <!-- Page Heading -->
    <div class="ps-form__header">
      <h3>Edit Runner Category</h3>
    </div>

    <!-- Content Row -->
    <RunnerCategoryForm :runner-category-id="$router.params.id"></RunnerCategoryForm>
  </div>
</template>

<script>
import RunnerCategoryForm from "./RunnerCategoryForm";
export default {
  name: "EditRunnerCategory",
  components: {RunnerCategoryForm}
}
</script>

<style scoped>

</style>